import firebase from "firebase/compat/app"; //see https://stackoverflow.com/questions/68946446/how-do-i-fix-a-firebase-9-0-import-error-attempted-import-error-firebase-app
import "firebase/compat/firestore";
import "firebase/compat/auth";

// EFL-test configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyCc5giHfmkinmY4wsJjSDODwIQNlnH4tTQ",
//   authDomain: "efltest.firebaseapp.com",
//   projectId: "efltest",
//   storageBucket: "efltest.appspot.com",
//   messagingSenderId: "578788165138",
//   appId: "1:578788165138:web:8cbe3714a271837b113acb",
//   measurementId: "G-S463H03Z3N"
// };

// Prod config below
const firebaseConfig = {
  apiKey: "AIzaSyDsQgmwsha8RO0BjuWRR4WPjMeSiGFmNQg",
  authDomain: "endless-frontier-labs-dd34a.firebaseapp.com",
  projectId: "endless-frontier-labs-dd34a",
  storageBucket: "endless-frontier-labs-dd34a.appspot.com",
  messagingSenderId: "33812164865",
  appId: "1:33812164865:web:171ec3bec58c8b943dab00",
  measurementId: "G-GX7F7W0LD8",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const auth = firebase.auth();
export default app;
  /*

The code below is from firebase v8 and is the namespaced version. In version 9, seems like
firebase is using the modular version. The code is entirely different and in order to fully
upgrade to firebase v9, we need to update AuthContext.js and many other parts of the codebase.
For now, we updated the firebase version from v8->v9 as of March2nd,2023. We are using backward compat
as can be seen from lines 1-3.

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'


const app = firebase.initializeApp({
  apiKey: "AIzaSyDsQgmwsha8RO0BjuWRR4WPjMeSiGFmNQg",
  authDomain: "endless-frontier-labs-dd34a.firebaseapp.com",
  projectId: "endless-frontier-labs-dd34a",
  storageBucket: "endless-frontier-labs-dd34a.appspot.com",
  messagingSenderId: "33812164865",
  appId: "1:33812164865:web:171ec3bec58c8b943dab00",
  measurementId: "G-GX7F7W0LD8"
});

export const auth = app.auth()
export const auth2 = app.auth()
export const db = app.firestore()
export default app
*/
