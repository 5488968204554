import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/Home";
import SignUp from "./components/Signup";
import { AuthProvider } from "./contexts/AuthContext";
import UserProfile from "./components/UserProfile";
import UpdateProfile from "./components/UpdateUserProfile";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import NavBar from "./components/NavBar";
import Dashboard from "./components/Dashboard";

const App = (props) => (
  <Router>
    <Switch>
      <AuthProvider>
        <NavBar></NavBar>
        <PrivateRoute exact path="/" component={Dashboard} />
        <Route path="/login" component={Login} />
        <PrivateRoute path="/signup" component={SignUp} />
        <PrivateRoute path="/profile" component={UserProfile} />
        {/** <PrivateRoute path = "/profile-update" component = {UpdateProfile}/> **/}
      </AuthProvider>
    </Switch>
  </Router>
);

export default App;
