import React, { useRef, useState } from "react";
import { Button, Card, Container, Alert, InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import firebase from "firebase/compat/app";
// User ../config.json for prod config and ../config-test.json for test config
import config from "../config.json";
// import config from "../config-test.json";
import { auth } from "../firebase";

const SignUp = (props) => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const nameRef = useRef();
  const passwordConfirmRef = useRef();
  const roleRef = useRef();
  const { currentUser } = useAuth();
  //const {signup} = useAuth()
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [generatedPassword, setGeneratedPassword] = useState("");
  const [generatedConfirmPassword, setGeneratedConfirmPassword] = useState("");
  const history = useHistory();

  function handlePasswordChange(event) {
    setGeneratedPassword(event.target.value);
  }

  function handleConfirmedPasswordChange(event) {
    setGeneratedConfirmPassword(event.target.value);
  }

  function copyPassword() {
    navigator.clipboard.writeText(generatedPassword);
  }

  function generateStrongPassword() {
    const validUpperCaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const validLowerCaseLetters = "abcdefghijklmnopqrstuvwxyz";
    const validNumbers = "0123456789";
    const validSpecialCharacters = "!#%^*_:<>?-=,./";

    // Define a variable to store the generated password
    let password = "";

    // Define the minimum and maximum length of the password
    const minLength = 12;
    const maxLength = 15;

    // Define a function to generate a random character from a given string
    function getRandomCharacterFromString(string) {
      const index = Math.floor(Math.random() * string.length);
      return string.charAt(index);
    }

    // Generate at least one uppercase letter, one lowercase letter, one number, and one special character
    password += getRandomCharacterFromString(validUpperCaseLetters);
    password += getRandomCharacterFromString(validLowerCaseLetters);
    password += getRandomCharacterFromString(validNumbers);
    password += getRandomCharacterFromString(validSpecialCharacters);

    // Generate the remaining characters of the password
    const remainingLength =
      Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength - 4;
    for (let i = 0; i < remainingLength; i++) {
      const validCharacters =
        validUpperCaseLetters +
        validLowerCaseLetters +
        validNumbers +
        validSpecialCharacters;
      password += getRandomCharacterFromString(validCharacters);
    }

    // Shuffle the characters of the password
    password = password
      .split("")
      .sort(function () {
        return 0.5 - Math.random();
      })
      .join("");

    return password;
  }

  function generatePassword() {
    const strongPassword = generateStrongPassword();
    setGeneratedPassword(strongPassword);
    setGeneratedConfirmPassword(strongPassword);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("passwords do not match");
    }

    try {
      setError("");
      setLoading(true);

      // We are creating a second instance of the firebase application since when we try to signup a new user using
      // the first instance we had created, it automatically does a 'signin' for the new user and that signs out the current admin user who originally
      // creates the account. This way we do not have to worry about the admin being signed out in the first instance.
      const secondaryApp = firebase.initializeApp(config, "Secondary");
      let newUserId = null;

      await firebase
        .firestore()
        .collection("users")
        .doc(currentUser.uid)
        .get()
        .then(async (doc) => {
          console.log("Doc accessed");
          // We need to check if the user is an admin as only they have the permissions to create new users
          if (doc.data().accountType === "admin") {
            console.log("User is an admin");

            await secondaryApp
              .auth()
              .createUserWithEmailAndPassword(
                emailRef.current.value,
                passwordRef.current.value
              )
              .then(async (user) => {
                newUserId = user.user.uid;
              });

            let data = {
              email: emailRef.current.value,
              displayName: nameRef.current.value,
              accountType: roleRef.current.value,
            };

            // Creating document in firestore with the properties of the newly created user
            await secondaryApp
              .firestore()
              .collection("users")
              .doc(newUserId)
              .set(data)
              .then(() => {
                setSuccessMessage(
                  `Account for ${data.displayName} successfully created!`
                );
                // Timeout to let user read success message
                setTimeout(() => {
                  history.push("/profile");
                }, 2000);
              })
              .catch(function (error) {
                console.log("Error adding document: ", error);
              });
          } else {
            setError("You do not have permission to create accounts");
          }
        })
        .catch((error) => {
          setError("Failed to create new user " + error.message);
        });
    } catch (err) {
      setError("Failed to create an account. Please try again." + err);
    }

    setLoading(false);
  }

  return (
    <React.Fragment>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <div className="w-100" style={{ maxWidth: "400px" }}>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">Sign Up</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              {successMessage && (
                <Alert variant="success">{successMessage}</Alert>
              )}
              <Form className="m-auto" onSubmit={handleSubmit}>
                <Form.Group id="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" ref={nameRef} required />
                </Form.Group>
                <Form.Group id="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" ref={emailRef} required />
                </Form.Group>
                <Form.Group id="password">
                  <Form.Label>Password</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      value={generatedPassword}
                      onChange={(e) => handlePasswordChange(e)}
                      type="text"
                      ref={passwordRef}
                      required
                    />
                    <Button
                      onClick={copyPassword}
                      size="sm"
                      variant="outline-primary"
                    >
                      <HiOutlineClipboardCopy size="25" />
                    </Button>
                  </InputGroup>
                  <Form.Group
                    style={{ marginTop: "-10px", marginBottom: "6px" }}
                  >
                    <Button
                      onClick={generatePassword}
                      size="sm"
                      variant="outline-primary"
                      className="w-50"
                      type="button"
                      disabled={loading}
                    >
                      Create strong password
                    </Button>
                  </Form.Group>
                </Form.Group>
                {/*Remove Password Confirm and */}
                <Form.Group id="password-confirm">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    value={generatedConfirmPassword}
                    onChange={(e) => handleConfirmedPasswordChange(e)}
                    type="text"
                    ref={passwordConfirmRef}
                    required
                  />
                </Form.Group>
                <Form.Group id="role-type">
                  <Form.Label>Set User Role</Form.Label>
                  <Form.Row style={{ marginRight: 0, marginLeft: 0 }}>
                    <Form.Control as="select" size="md" ref={roleRef} required>
                      <option></option>
                      <option value="dev">Dev</option>
                      <option value="admin">Admin</option>
                    </Form.Control>
                  </Form.Row>
                </Form.Group>
                <Button type="submit" disabled={loading}>
                  {" "}
                  Sign Up
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default SignUp;
