import React, { useState, useEffect } from "react";
import {
  Nav,
  Navbar,
  Image,
  Dropdown,
  NavItem,
  NavLink,
  NavDropdown,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const NavBar = (props) => {
  const { currentUser, logout } = useAuth();
  const [error, setError] = useState("");
  const history = useHistory();
  //<Nav.Item><Nav.Link style={{'color': '#bbb'}} href="/profile"> Profile </Nav.Link> </Nav.Item>
  async function handleLogout() {
    setError("");
    try {
      await logout();
      history.push("/");
    } catch {
      setError("Failed to log out.");
    }
  }

  if (currentUser) {
    return (
      <Navbar expand="lg" style={{ backgroundColor: "#222" }}>
        <Navbar.Brand href="/" style={{ color: "white" }}>
          <img
            src="/EFL-Logo.png"
            width="230"
            height="40"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto" style={{ color: "#bbb" }}>
            <Nav.Item>
              <Nav.Link style={{ color: "#bbb" }} href="/">
                {" "}
                Dashboard{" "}
              </Nav.Link>{" "}
            </Nav.Item>
            <Dropdown as={NavItem} style={{ width: "200px" }}>
              <Dropdown.Toggle style={{ color: "white" }} as={NavLink}>
                Settings
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item disabled>
                  <p>Logged in as:</p>
                  <p>{currentUser.displayName}</p>
                </Dropdown.Item>
                <NavDropdown.Divider />
                <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                <Dropdown.Item href="/signup">Sign Up New User</Dropdown.Item>
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  } else {
    return (
      <Navbar expand="lg" style={{ "background-color": "#222" }}>
        <Navbar.Brand href="/" style={{ color: "white" }}>
          {" "}
          EFL Dashboard{" "}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto" style={{ color: "#bbb" }}>
            <Nav.Item>
              <Nav.Link style={{ color: "#bbb" }} href="/profile">
                {" "}
                Home{" "}
              </Nav.Link>{" "}
            </Nav.Item>
            <Nav.Item>
              <Nav.Link style={{ color: "#bbb" }} href="/login">
                {" "}
                Log In{" "}
              </Nav.Link>{" "}
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
};

export default NavBar;
